Reset and basic styles * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: inherit;
}

b {
    font-weight: bold;
}

.questPage {
    width: 100vw;
    height: 100vh;
    background-color: #212121;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 10px;
    padding-top: 10dvh;
    padding-bottom: 10dvh;
    overflow-y: auto;
}

.questPage-questCardBorder {
    width: 40%;
    height: 100%;
    padding: 10px;
}

.questPage-questCard {
    width: 100%;
    height: 100%;
    height: fit-content;
    padding: 10px;
    border-radius: 20px;
    background-color: #333333;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    color: white;
}

.questPage-questCardHeader {
    width: 100%;
    padding: 10px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.questPage-questCardDetail {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px;
}

.questPage-questCardIcon {
    display: flex;
    width: 50%;
    height: 100%;
    padding: 10px;
}

.questPage-questCardInfoBorder {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%; 
    padding: 10px;
}


.questPage-questCardInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    padding: 10px;
    font-size: 16px;
    background-color: #484848;
    border-radius: 16px;
    margin-top: 10px;
}

.questPage-questCardInfo-questName {
    display: flex;
    flex-direction: row;
    width: 50%;
    padding: 10px;
    font-size: 16px;
    background-color: #484848;
    border-radius: 16px;
}

.questPage-questCardInfo-questReward {
    width: 50%;
    padding: 10px;
    font-size: 16px;
    background-color: #484848;
    border-radius: 16px;
    text-align: right;
}


.questPage-questCardInfo-recipeInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
    text-align: center;
}

.questPage-questCardInfo-recipeInfoText {
    width: 100%;
    font-size: 12px;
    text-align: left;
    margin-top: 5px;
}

.questPage-questCardInfo-enemyDrop {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
}

.questPage-questCardInfo-enemyDropHeader {
    width: 100%;
    font-size: 16px;
    font-weight: bold;
}

.questPage-questCardIcon-dropIcon {
    width: 20%;
    margin-right: 5px;
}

.questPage-questCardFooter {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #484848;
    width: 100%;
    padding: 10px;
    border-radius: 16px;
}

.questPage-questCardFooter-recipeCount {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #484848;
    width: 100%;
    padding: 10px;
    border-radius: 16px;

}

.questPage-questCardFooter-itemBlock {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    width: 20%;
}

.questPage-questCardFooter-itemIcon {
    width: 30%;
}

.questPage-joinZoneButton {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    background-color: #00ff00;
    border-radius: 12px;
}

.questPage-questCardFooter-idleText {
    display: flex;
    flex-direction: column;
    width: 60%;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
}

.questPage-claimButton {
    width: 30%;
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
    background-color: #00ff00;
    border-radius: 12px;
}

.questPage-questCardFooter-blockText {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
}

@media (max-width: 768px) {
    .questPage {
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: start;
    }

    .questPage-questCardBorder {
        width: 100%;
    }
}