.adventureBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 9dvh;
    padding: 10px;
    border-radius: 0px 0px 20px 20px;
    background-color: #333333;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

.adventureBar-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: bold;
    color: white;
    max-width: 40%;
    height: 100%;
    padding: 10px;
}

.adventureBar-cash {
    display: flex;
    justify-content: space-between;
    width: 40%;
    height: 100%;
    padding: 10px;
    background-color: #212121;
    border-radius: 16px;
}

.adventureBar-cash-icon {
    height: 100%;
}

.adventureBar-cash-text {
    display: flex;
    align-items: center;
    justify-content: right;
    font-size: 18px;
    font-weight: bold;
    color: white;
    width: 100%;
    height: 100%;
    padding: 10px;
}
/* .profileBar {
    position: fixed;
    top: 0%;
    left: 0%;
    background-color: #333333;
    color: white;
    padding: 10px;
    border-radius: 0px 0px 20px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100vw;
    height: 9dvh;
}

.experienceCard,
.cashCard,
.goldCard {
    display: flex;
    background-color: #212121;
    border-radius: 20px;
    width: 100%;
    margin-left: 5px;
    margin-right: 5px;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
}

.progressXPBase {
    width: 100%;
    background-color: white;
    border-radius: 20px;
    padding: 5px;
}

.progressXP {
    text-align: center;
    color: #00ff00;
    min-width: 1px;
    max-width: 100%;
    border-radius: 20px;
    background-color: #00ff00;
    font-size: 9px;
}

.experienceCardRight,
.cashCardRight,
.goldCardRight {
    width: 100%;
    text-align: center;
    justify-content: space-between;
}

.experienceHeaderText,
.cashHeaderText,
.goldHeaderText {
    font-size: 16px;
    margin-bottom: 5px;
}

.cashText,
.goldText {
    font-size: 28px;
}

.navButton {
    text-align: center;
    align-items: center;
}

.cashCard img,
.goldCard img {
    width: 64px;
    max-height: 100%;
    margin-right: 10px;
}



@media (max-width: 768px) {

    .experienceCard {
        min-width: 30%;
    }

    .cashCard {
        min-width: 40%;
    }
    
    .cashCard img,
    .goldCard img {
        width: 32px;
        max-height: 100%;
        margin-right: 10px;
    }

    .experienceHeaderText {
        font-size: 10px;
        margin-bottom: 5px;
    }

    .cashHeaderText,
    .goldHeaderText {
        font-size: 10px;
        margin-bottom: 5px;
    }

    .cashText,
    .goldText {
        font-size: 20px;
    }
} */