/* Colour Schemes */
* {
    --background-color: #252321;
    --main-card-color: #e9a7c1;
    --secondary-card-color: rgba(255, 255, 255, 0.5);
    --header-footer-color: #f8c8dc;
    --primary-text-color: #000000;
    --secondary-text-color: #FFFFFF;
    --button-color: #F2888B;
    /*  */
}

/* CNY Event Feature */
.eventOverlay {
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 10dvh;
    padding-bottom: 5dvh;
    width: 100dvw;
    height: 100dvh;
    overflow: hidden;
    pointer-events: none;
}

.falling-firecracker {
    position: absolute;
    width: 10px;
    height: 20px;
    background: darkred;
    border-radius: 20px;
    /* box-shadow: 0 0 10px red, 0 0 20px orange; */
    animation: fall 4s linear forwards;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.spark {
    position: absolute;
    width: 10px;
    height: 10px;
    background: yellow;
    border-radius: 50%;
    box-shadow: 0 0 5px yellow, 0 0 10px orange;
    animation: spark-move 1s linear forwards;
    transform: translate(var(--dx), var(--dy));
    opacity: 0.8;
}

@keyframes fall {
    0% {
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        transform: translateY(50dvh);
        opacity: 0;
    }
}

@keyframes spark-move {
    0% {
        transform: translate(0, 0);
        opacity: 0.5;
    }

    50% {
        transform: translate(calc(var(--dx) * 1px), calc(var(--dy) * 1px));
        opacity: 1;
    }

    85% {
        transform: translate(calc(var(--dx) * 2px), calc(var(--dy) * 2px));
        opacity: 0.5;
    }

    100% {
        transform: translate(calc(var(--dx) * 2px), calc(var(--dy) * 2px));
        opacity: 0;
    }
}


/* Font Sizes */
h1 {
    font-size: 24px;
    font-weight: bold;
    color: var(--primary-text-color);
}

h2 {
    font-size: 20px;
    font-weight: bold;
    color: var(--primary-text-color);
}

h3 {
    font-size: 16px;
    font-weight: bold;
    color: var(--primary-text-color);
}

h4 {
    font-size: 12px;
    font-weight: bold;
    color: var(--primary-text-color);
}

@media (max-width: 768px),
(orientation: portrait) {

    /* Font Sizes */
    h1 {
        font-size: 16px;
        font-weight: bold;
        color: var(--primary-text-color);
    }

    h2 {
        font-size: 14px;
        font-weight: bold;
        color: var(--primary-text-color);
    }

    h3 {
        font-size: 12px;
        font-weight: bold;
        color: var(--primary-text-color);
    }

    h4 {
        font-size: 10px;
        font-weight: bold;
        color: var(--primary-text-color);
    }
}

/* Spacers */
.top-margin-10 {
    margin-top: 10px;
}

/* Device Main Screen */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.deviceScreenBorder {
    width: 100svw;
    height: 100svh;
    padding: 10px;
    padding-top: 10dvh;
    padding-bottom: 10dvh;
    background-color: var(--background-color);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}

/* Page Headers */
.headerBar {
    display: flex;
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100dvw;
    height: 9dvh;
    padding: 10px 10px 10px 20px;
    background-color: var(--header-footer-color);
    border-radius: 0px 0px 20px 20px;
    justify-content: space-between;
    align-items: center;
}

.headerBar-Item {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 20%;
    height: 100%;
}

.cashItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 24px;
    padding: 0px 20px;
    width: 100%;
    height: 100%;
}

.cashItem-Icon {
    height: 100%;
}

.cashItem-Text {
    width: 100%;
    font-size: 24px;
    font-weight: bold;
    text-align: right;
    color: var(--primary-text-color);
}

@media (max-width: 768px),
(orientation: portrait) {
    .headerBar-Item {
        width: 40%;
    }

    .cashItem-Icon {
        width: 30%;
        height: auto;
    }

    .cashItem-Text {
        font-size: 18px;
    }
}

/* Home Page Cards */
.homeCard-1 {
    width: 100%;
    height: 20%;
    padding: 10px;
    text-align: center;

}

.homeCard-3 {
    width: 33.3%;
    height: 30%;
    padding: 10px;
    text-align: center;
}

.homeCard-Detail {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 10px;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    background-color: var(--main-card-color);
}

@media (max-width: 768px),
(orientation: portrait) {

    .seasonHome {
        width: 100%;
        height: 20%;
    }

    .guideHome {
        width: 100%;
        height: 10%;
    }

    .adventureHome {
        width: 100%;
        height: 40%;
    }

    .battleHome,
    .kitchenHome {
        width: 50%;
        height: 20%;
    }

    .orderHome,
    .questHome,
    .parcelHome {
        width: 33.3%;
        height: 10%;
    }
}

/* Grids */
.adventureGrid {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    background-color: var(--background-color);
    overflow-y: scroll;
}

.gridCard-4 {
    width: 25%;
    height: fit-content;
    padding: 10px;
}

.gridCard-Detail {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 16px;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    background-color: var(--main-card-color);
}

.gridCard-Text {
    color: var(--primary-text-color);
}

.gridCard-CTA {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--secondary-card-color);
    width: 100%;
    padding: 10px;
    border-radius: 16px;
    color: var(--secondary-text-color);
}

.gridCard-Button {
    width: 30%;
    height: 100%;
    padding: 10px;
    border-radius: 12px;
    font-size: 16px;
    font-weight: bold;
    background-color: var(--button-color);
}


@media (max-width: 768px),
(orientation: portrait) {
    .adventureGrid {
        flex-direction: column;
    }

    .gridCard-4 {
        width: 100%;
    }
}

/* Coming Soon Page */
.comingSoonScreen {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.comingSoonLogo {
    width: 20dvw;
    animation: icon-spin infinite 60s linear;
}

@keyframes icon-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

/* Adventure Page */
.adventureCard {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    padding: 20px;
    border-radius: 20px;
    background-color: var(--main-card-color);
}

.adventureCard-zoneDetail {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: fit-content;
    justify-content: space-around;
}

.adventureCard-enemyIcon {
    display: flex;
    height: 80%;
}

.adventureCard-zoneInfo {
    width: 50%;
    height: fit-content;
}

.adventureCard-zoneDropIcon {
    width: 20%;
    margin-right: 5px;
}

.adventureCard-zoneFooter {
    display: flex;
    flex-direction: column;
}

/* Receipt Menu */
.adventure-reward-screen {
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100vw;
    height: 100vh;
    background-color: rgb(0, 0, 0, 0.8);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.adventure-reward-popup {
    width: 30%;
    background-color: white;
    border-radius: 20px;
    padding: 20px;
}

.adventure-reward-header {
    width: 100%;
    font-size: 24px;
    font-weight: bold;
    color: black;
    text-align: center;
}

.adventure-reward-item-header,
.adventure-reward-item-description {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    justify-content: space-between;
}

.adventure-reward-item-header-text {
    width: 30%;
    font-size: 16px;
    font-weight: bold;
    color: black;
    text-align: center;
    padding: 10px;
}

.adventure-reward-item-description-text {
    font-size: 16px;
    color: black;
    text-align: center;
    padding: 10px;
}

.adventure-reward-footer {
    display: flex;
    flex-direction: column;
    justify-content: right;
}

.adventure-reward-footer-text {
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    color: black;
    text-align: right;
    padding: 2px;
}

.adventure-reward-button-toggle {
    width: 100%;
    padding: 10px;
    border-radius: 16px;
    font-size: 16px;
    font-weight: bold;
    color: black;
    background-color: greenyellow;
    margin-top: 10px;
}

@media (max-width: 768px) {
    .adventure-reward-popup {
        width: 90%;
    }
}