/* Reset and basic styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: inherit;
}

/* Main screen container */
.inventoryScreen {
    width: 100%;
    height: 100dvh;
    background-color: #212121;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    padding-top: 10vh;
    padding-bottom: 7vh;
    overflow-y: auto;
}

.inventoryHeader {
    flex-shrink: 0;
    font-size: 3em;
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
}

/* Inventory Card container */
.inventoryCard {
    flex-shrink: 0;
    /* Prevents shrinking */
    width: 90%;
    background-color: #333333;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    text-align: center;
    margin-top: 20px;
}

.inventoryCard h3 {
    color: white;
    font-size: 1.5em;
}

/* Inventory Items */
.inventoryCategory {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-items: center;
}

.inventory-item {
    width: 130px;
    background-color: #1e1e1e;
    border-radius: 8px;
    text-align: center;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    margin-top: 10px;
}

.inventory-item:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.2);
}

.item-icon {
    width: 60px;
    height: 60px;
}

.item-info {
    display: flex;
    font-size: 1.2em;
    color: #fff;
    flex-direction: column;
}

.item-name {
    font-weight: normal;
}

.item-quantity {
    opacity: 0.7;
}

/* Media Queries */
@media (max-width: 768px) {
    .inventoryCard {
        padding: 15px;
    }

    .inventoryCategory {
        grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
        gap: 5px;
    }

    .inventory-item {
        flex-direction: row;
        width: 100%;
    }

    .item-info {
        width: 100%;
        flex-direction: column;
        text-align: right;
        font-size: 0.9em;
    }

    .item-icon {
        width: 60px;
        height: 60px;
    }
}