.battleScreen {
    width: 100%;
    height: 100vh;
    /* Ensures it spans the full viewport height */
    background-color: #212121;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    padding-top: 10vh;
    /* Prevents overlap with the header */
    padding-bottom: 7vh;
    /* Prevents overlap with the footer/navBar */
    overflow-y: auto;
    /* Allows scrolling */
}

.battleHeader {
    flex-shrink: 0;
    font-size: 3em;
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
}

.dungeon,
.arena,
.unranked {
    flex-shrink: 0;
    /* Prevents shrinking */
    width: 90%;
    background-color: #333333;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    text-align: center;
    margin-bottom: 20px;
}

.dungeon h2,
.arena h2 {
    color: white;
    margin-bottom: 20px;
    font-size: 3em;
}

.dungeon h3,
.arena h3 {
    text-align: left;
    color: white;
    margin-bottom: 20px;
    font-size: 1.5em;
}

.joinDungeon {
    width: 100%;
    font-size: 2em;
    padding: 10px;
    border-radius: 10px;
}