/* Colour Schemes */
* {
    --background-color-2: #252321;
    --main-card-color-2: #e9a7c1;
    --secondary-card-color: #A3262A;
    --header-footer-color-2: #f8c8dc;
    --primary-text-color-2: #000000;
    --secondary-text-color: #FFFFFF;
    --button-color: #F2888B;
    /*  */
}

h6 {
    position: relative;
    bottom: 0%;
    font-size: 14px;
    color: var(--primary-text-color-2);
    font-weight: bold;
}

h7 {
    position: relative;
    bottom: 0%;
    font-size: 10px;
    color: var(--primary-text-color-2);
    font-weight: bold;
}

.deviceScreen2 {
    display: flex;
    flex-direction: column;
    width: 100svw;
    height: 100svh;
    padding: 10svh 0 10svh 0;
    background-color: var(--background-color-2);
}

.header2Bar {
    position: fixed;
    display: flex;
    top: 0;
    width: 100svw;
    height: 10svh;
    padding: 5px 10px;
    justify-content: space-between;
    align-items: center;
    background-color: var(--header-footer-color-2);
}

.headerBar2-Item {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 20%;
    height: 80%;
}


.cash2Item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 24px;
    padding: 0px 10px;
    width: 100%;
    height: 100%;
}

.cash2Item-Icon {
    height: 75%;
}

.cash2Item-Text {
    width: 50%;
    font-size: 18px;
    font-weight: bold;
    text-align: right;
    color: var(--primary-text-color);
}

.footer2Bar {
    position: fixed;
    display: flex;
    flex-direction: row;
    bottom: 0;
    width: 100svw;
    height: 10svh;
    background-color: var(--header-footer-color-2);
}

.homeScreen2 {
    display: flex;
    width: 100%;
    height: 100%;
}

.homeScreen2Left,
.homeScreen2Right {
    display: flex;
    flex-direction: column;
    width: 20%;
    height: 100%;
    justify-content: top;
    align-items: center;
    padding: 20px 10px 20px 10px;
}

.homeScreen2Main {
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 100%;
    padding: 20px 10px 20px 10px;
    justify-content: space-between;
    align-items: center;
}

.homeScreenIcon {
    display: flex;
    justify-content: center;
    align-items: end;
    padding: 5px;
    width: 40%;
    margin-top: 20px;
    aspect-ratio: 1;
    border-radius: 20px;
    background-color: var(--main-card-color-2);
}

.footerIcon {
    display: flex;
    justify-content: center;
    align-items: end;
    margin: 1%;
    padding: 1%;
    width: 20%;
    height: 90%;
    border-radius: 20px;
    /* background-color: var(--main-card-color-2); */
}

.zoneMainIcon {
    width: 30%;
}

.seasonBox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    aspect-ratio: 6;
    padding: 5px;
    border-radius: 20px;
    background-color: var(--main-card-color-2);
}

.claimBox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    aspect-ratio: 6;
    padding: 5px;
    border-radius: 20px;
    background-color: var(--main-card-color-2);
}

@media (max-width: 768px),
(orientation: portrait) {
    .homeScreenIcon {
        width: 100%;
    }

    .zoneMainIcon {
        width: 75%;
    }

    .homeScreen2Main {
        justify-content: space-between;
    }

    .seasonBox {
        width: 100%;
        aspect-ratio: 3;
        max-height: 100px;
    }

    .claimBox {
        width: 100%;
        aspect-ratio: 3;
        max-height: 100px;
        margin-bottom: 15svh;
    }

    .homeScreen2Left,
    .homeScreen2Right {
        padding: 100px 10px 20px 10px;
    }


    .headerBar2-Item {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 35%;
        height: 100%;
    }

    .cash2Item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 24px;
        padding: 0px 10px;
        width: 100%;
        height: 75%;
    }

    .cash2Item-Icon {
        width: 25%;
        height: auto;
    }

    .cash2Item-Text {
        width: 100%;
        font-size: 16px;
        font-weight: bold;
        text-align: right;
        color: var(--primary-text-color);
    }
}