Reset and basic styles * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: inherit;
}

b {
    font-weight: bold;
}

.kitchenPage {
    width: 100vw;
    height: 100vh;
    background-color: #212121;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px;
    padding-top: 10dvh;
    padding-bottom: 10dvh;
    overflow-y: auto;
}

.kitchenPage-dishCardBorder {
    width: 20%;
    padding: 10px;
}

.kitchenPage-dishCard {
    width: 100%;
    padding: 10px;
    border-radius: 20px;
    background-color: #333333;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    color: white;
}

.kitchenPage-dishCardHeader {
    width: 100%;
    padding: 10px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.kitchenPage-dishCardDetail {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: 10px;
}

.kitchenPage-dishCardIcon {
    display: flex;
    width: 50%;
    height: 100%;
    padding: 10px;
}

.kitchenPage-dishCardInfo {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 10px;
}

.kitchenPage-dishCardInfo-recipeInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
    text-align: left;
}

.kitchenPage-dishCardInfo-recipeInfoText {
    width: 100%;
    font-size: 12px;
    text-align: left;
    margin-top: 5px;
}

.kitchenPage-dishCardInfo-enemyDrop {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
}

.kitchenPage-dishCardInfo-enemyDropHeader {
    width: 100%;
    font-size: 16px;
    font-weight: bold;
}

.kitchenPage-dishCardIcon-dropIcon {
    width: 20%;
    margin-right: 5px;
}

.kitchenPage-dishCardFooter {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #484848;
    width: 100%;
    padding: 10px;
    border-radius: 16px;
}

.kitchenPage-dishCardFooter-recipeCount {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #484848;
    width: 100%;
    padding: 10px;
    border-radius: 16px;

}

.kitchenPage-dishCardFooter-itemBlock {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    width: 20%;
}

.kitchenPage-dishCardFooter-itemIcon {
    width: 50%;
}

.kitchenPage-joinZoneButton {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    background-color: #00ff00;
    border-radius: 12px;
}

.kitchenPage-dishCardFooter-idleText {
    display: flex;
    flex-direction: column;
    width: 60%;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
}

.kitchenPage-claimButton {
    width: 30%;
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
    background-color: #00ff00;
    border-radius: 12px;
}

.kitchenPage-dishCardFooter-blockText {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
}

@media (max-width: 768px) {
    .kitchenPage {
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: start;
    }

    .kitchenPage-dishCardBorder {
        width: 100%;
    }
}