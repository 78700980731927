.battlefieldScreen {
    width: 100%;
    height: 100vh;
    background-color: #212121;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    overflow-y: auto;
}

.battlefieldHeader {
    flex-shrink: 0;
    font-size: 3em;
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
}

.battlefieldEnemy,
.battlefieldPlayer,
.battlefieldAction {
    flex-shrink: 0;
    width: 90%;
    background-color: #333333;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    text-align: center;
    margin-bottom: 20px;
}

.battlefieldEnemy h2,
.battlefieldPlayer h2,
.battlefieldAction h2 {
    color: white;
    margin-bottom: 20px;
    font-size: 2em;
}

.battlefieldEnemy h3,
.battlefieldPlayer h3,
.battlefieldAction h3 {
    text-align: center;
    color: white;
    margin-bottom: 20px;
    font-size: 1.5em;
}

.battlefieldEnemy img {
    max-height: 10vh;
}

.healthBarBox {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 3vh;
}

.healthBase {
    width: 100%;
    background-color: white;
    border-radius: 12px;
    padding: 5px;
}

.healthBar {
    max-width: 100%;
    height: 100%;
    background-color: red;
    border-radius: 12px;
    color: red;
}

.actionGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30vw, 1fr));
    gap: 10px;
    justify-items: center;
}

.actionGrid button {
    width: 100%;
    height: 5vh;
    font-size: 2em;
    border-radius: 20px;
}

.joinDungeon {
    width: 100%;
    font-size: 2em;
    padding: 10px;
    border-radius: 10px;
}