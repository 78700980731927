.loginScreen {
  width: 100vw;
  height: 100vh;
  position: fixed;
  text-align: center;
  background-color: #212121;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.logo {
  max-height: 25vh;
  pointer-events: none;
  padding-bottom: 20px;
}

.loginCard {
  display: flex;
  flex-direction: column;
  background-color: #333333;
  border-radius: 20px;
  padding: 20px;
  justify-content: center;
  align-items: center;
}

.header {
  color: white;
  font-size: 4em;
  padding: 20px;
}

.loginTextField {
  width: 100%;
  font-size: 1.5em;
  background-color: #A0A0A0;
  color: white;
  border-radius: 10px;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.createButton {
  width: 100%;
  color: white;
  background-color: #808080;
  font-size: 1.5em;
  border-radius: 10px;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
}


.loginButton {
  width: 100%;
  background-color: #00FF00;
  font-size: 1.5em;
  border-radius: 10px;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {

  /* Styles for mobile screens */
  .loginCard {
    width: 80%;
  }
}

@media (min-width: 769px) {

  /* Styles for larger screens */
  .loginCard {
    width: 600px;
  }
}