.kitchenBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 9vh;
    padding: 10px;
    border-radius: 0px 0px 20px 20px;
    background-color: #333333;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

.kitchenBar-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: bold;
    color: white;
    max-width: 40%;
    height: 100%;
    padding: 10px;
}

.kitchenBar-cash {
    display: flex;
    justify-content: space-between;
    width: 20%;
    height: 100%;
    padding: 10px;
    background-color: #212121;
    border-radius: 16px;
}

.kitchenBar-cash-icon {
    height: 100%;
}

.kitchenBar-cash-text {
    display: flex;
    align-items: center;
    justify-content: right;
    font-size: 18px;
    font-weight: bold;
    color: white;
    width: 100%;
    height: 100%;
    padding: 10px;
}

@media (max-width: 768px) {
    .kitchenBar-cash {
        width: 40%;
    }
}