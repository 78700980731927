/* Reset and basic styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: inherit;
}

/* Main screen container */
.hqScreen {
    width: 100%;
    height: 100vh;
    background-color: #212121;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    padding-top: 10vh;
    padding-bottom: 7vh;
    overflow-y: auto;
}

.hqHeader {
    flex-shrink: 0;
    font-size: 32px;
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
}

/* Inventory Card container */
.hqCard {
    flex-shrink: 0; /* Prevents shrinking */
    width: 100%;
    background-color: #333333;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    text-align: center;
    margin-bottom: 20px;
}

.hqCard h3 {
    color: white;
    margin-bottom: 20px;
    font-size: 24px;
}

/* Inventory Items */
.hqCategory {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    gap: 10px;
    justify-items: center;
}

.hq-item {
    width: 130px;
    height: 130px;
    background-color: #1e1e1e;
    border-radius: 8px;
    text-align: center;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.item-icon {
    width: 60px;
    height: 60px;
}

.item-info {
    display: flex;
    font-size: 20px;
    color: #fff;
    flex-direction: column;
}

.item-name {
    font-weight: normal;
}

.item-quantity {
    opacity: 0.7;
}

/* Media Queries */
@media (max-width: 768px) {
    .hqCategory {
        grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
        gap: 5px;
    }

    .hq-item {
        width: 90px;
        height: 130px;
    }
    
    .item-info {
        font-size: 16px;
    }

    .item-icon {
        width: 60px;
        height: 60px;
    }
}
