* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: inherit;
}

.profileScreen {
    width: 100%;
    min-height: 100vh;
    background-color: #212121;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    padding: 30px;
    padding-top: 12vh;
    /* Prevents overlap with the header */
    padding-bottom: 7vh;
    /* Prevents overlap with the footer/navBar */
    overflow-x: unset;
}

.tempCard {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    width: 100%;
    background-color: #333333;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

.tempCard h1 {
    width: 100%;
    text-align: center;
    color: white;
    font-size: 28px;
}

.tempCard h2 {
    color: white;
    font-size: 24px;
}

.market-1 {
    width: 24%;
    color: white;
    background-color: #1e1e1e;
    border-radius: 8px;
    text-align: center;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    margin-top: 20px;
}

.equip-stat-text {
    margin-top: 10px;
    width: 100%;
    font-size: 24px;
    text-align: center;
}

.upgradeButton {
    margin-top: 20px;
    font-size: 20px;
    padding: 10px;
    border-radius: 12px;
    width: 100%;
}

/* Version 1 */
/* .market-item {
    width: 40%;
    color: white;
    background-color: #1e1e1e;
    text-align: center;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
} */

.market-item-left {
    width: 20%;
    color: white;
    background-color: #1e1e1e;
    text-align: center;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.market-item-right {
    width: 50%;
    color: white;
    background-color: #1e1e1e;
    text-align: center;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.market-item-cost {
    width: 100%;
    height: 50px;
    color: white;
    background-color: gray;
    text-align: center;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}

.market-item-cost-price {
    width: 30%;
    height: 100%;
    color: white;
    text-align: center;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    font-size: 16px;
}

.market-item-buy-button {
    width: 70%;
    height: 100%;
    color: white;
    background-color: #00ff00;
    text-align: center;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    font-size: 16px;
}

.market-item-description {
    width: 100%;
    margin-top: 10px;
    text-align: left;
    font-size: 12px;
}

/* Version 2 */
.market-item-2 {
    width: 24%;
    color: white;
    background-color: #1e1e1e;
    text-align: center;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 24px;
    margin-top: 10px;
    margin-left: 1%;
}

.market-item-top {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.market-item-left-2 {
    min-width: 20%;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.market-item-right-2 {
    min-width: 80%;
    margin-left: 10px;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.market-item-cost {
    width: 100%;
    color: white;
    background-color: gray;
    text-align: center;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    margin-top: 10px;
}

.market-item-cost-price {
    width: 50%;
    color: white;
    background-color: gray;
    text-align: center;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 20px;
    font-size: 20px;
}

.market-item-buy-button {
    width: 50%;
    color: white;
    background-color: #00ff00;
    text-align: center;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    font-size: 16px;
}

.cash-icon {
    height: 32px;
}

/* Receipt Menu */
.market-receipt-screen {
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100vw;
    height: 100vh;
    background-color: rgb(0, 0, 0, 0.8);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.market-receipt-popup {
    width: 30%;
    background-color: white;
    border-radius: 20px;
    padding: 20px;
}

.market-receipt-header {
    width: 100%;
    font-size: 24px;
    font-weight: bold;
    color: black;
    text-align: center;
}

.market-receipt-item-header,
.market-receipt-item-description {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    justify-content: space-around;
}

.market-receipt-item-header-text {
    width: 30%;
    font-size: 16px;
    font-weight: bold;
    color: black;
    text-align: center;
    padding: 10px;
}

.market-receipt-item-description-text {
    width: 30%;
    font-size: 16px;
    color: black;
    text-align: center;
    padding: 10px;
}

.market-receipt-footer {
    display: flex;
    flex-direction: column;
    justify-content: right;
}

.market-receipt-footer-text {
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    color: black;
    text-align: right;
    padding: 2px;
}

.market-receipt-button-toggle {
    width: 100%;
    padding: 10px;
    border-radius: 16px;
    font-size: 16px;
    font-weight: bold;
    color: black;
    background-color: greenyellow;
    margin-top: 10px;
}

.market-category-border {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 10px;
}

.market-category-header {
    width: 100%;
    padding: 10px;
    color: white;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}

.market-item-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
}

.market-item-border {
    width: 20%;
    padding: 10px;
}

.market-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    color: white;
    background-color: #333333;
    border-radius: 20px;
}

.market-item-detail {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: 10px;
}

.market-item-icon {
    width: 40%;
    padding: 5px;
}

.market-item-detail-name {
    width: 60%;
    padding: 10px;
    font-size: 24px;
    text-align: center;
    font-weight: bold;
}

.market-item-qtyList {
    width: 100%;
}

.market-item-qtyList-costPrice {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 5px;
    font-size: 16px;
    font-weight: bold;
    background-color: #1e1e1e;
    border-radius: 16px;
    margin-top: 10px;
}

.market-item-qtyList-cost {
    text-align: center;
    width: 50%;
    font-size: 16px;
    font-weight: bold;
}

.market-item-qtyButton {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    padding: 0px 10px;
    font-size: 12px;
    font-weight: bold;
    border-radius: 12px;
}

@media (max-width: 768px) {
    .profile-item {
        width: 100%;
    }

    .market-item-2 {
        width: 100%;
    }

    .market-item-2 h2 {
        font-size: 16px;
    }


    .market-item-2 h3 {
        font-size: 12px;
    }

    .market-1 {
        width: 100%;
    }

    .market-receipt-popup {
        width: 90%;
    }

    .market-item-border {
        flex: 0 0 auto;
        width: 99%;
    }

    .market-item-list {
        display: flex;
        flex-wrap: nowrap;
        padding: 10px;
        overflow-x: scroll;
    }
}