Reset and basic styles * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: inherit;
}

b {
    font-weight: bold;
}

.orderPage {
    width: 100vw;
    height: 100vh;
    background-color: #212121;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 10px;
    padding-top: 10dvh;
    padding-bottom: 10dvh;
    overflow-y: auto;
}

.orderPage-questCardBorder {
    width: 20%;
    height: 100%;
    padding: 10px;
}

.orderPage-questCard {
    width: 100%;
    height: 100%;
    height: fit-content;
    padding: 10px;
    border-radius: 20px;
    background-color: #333333;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    color: white;
}

.orderPage-questCardHeader {
    width: 100%;
    padding: 10px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.orderPage-questCardDetail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px;
}

.orderPage-questCardIcon {
    display: flex;
    width: 50%;
    height: 100%;
    padding: 10px;
}

.orderPage-questCardInfoBorder {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%; 
    padding: 10px;
}


.orderPage-questCardInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    padding: 10px;
    font-size: 16px;
    background-color: #484848;
    border-radius: 16px;
    margin-top: 10px;
}

.orderPage-questCardInfo-questName {
    display: flex;
    flex-direction: row;
    width: 80%;
    padding: 10px;
    font-size: 16px;
    background-color: #484848;
    border-radius: 16px;
}

.orderPage-questCardInfo-recipeInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
    text-align: center;
}

.orderPage-questCardInfo-recipeInfoText {
    width: 100%;
    font-size: 12px;
    text-align: left;
    margin-top: 5px;
}

.orderPage-questCardInfo-enemyDrop {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
}

.orderPage-questCardInfo-enemyDropHeader {
    width: 100%;
    font-size: 16px;
    font-weight: bold;
}

.orderPage-questCardIcon-dropIcon {
    width: 20%;
    margin-right: 5px;
}

.orderPage-questCardFooter {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #484848;
    width: 100%;
    padding: 10px;
    border-radius: 16px;
}

.orderPage-questCardFooter-recipeCount {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #484848;
    width: 100%;
    padding: 10px;
    border-radius: 16px;

}

.orderPage-questCardFooter-itemBlock {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    width: 20%;
}

.orderPage-questCardFooter-itemIcon {
    width: 30%;
}

.orderPage-joinZoneButton {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    background-color: #00ff00;
    border-radius: 12px;
}

.orderPage-questCardFooter-idleText {
    display: flex;
    flex-direction: column;
    width: 60%;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
}

.orderPage-claimButton {
    width: 30%;
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
    background-color: #00ff00;
    border-radius: 12px;
}

.orderPage-questCardFooter-blockText {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
}

@media (max-width: 768px) {
    .orderPage {
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: start;
    }

    .orderPage-questCardBorder {
        width: 100%;
    }
}