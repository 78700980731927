* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: inherit;
}

/* 
.adventureCard {
    width: 33.3%;
    height: 30%;
    padding: 10px;
    text-align: center;
} */

.adventureCardInfo {
    width: 100%;
    height: 100%;
    padding: 20px;
    background-color: #F5AC27;
    border-radius: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.adventureCard h1 {
    width: 100%;
    color: #000000;
    font-size: 32px;
    font-weight: bold;
    align-items: center;
}

.adventureCard h2 {
    width: 100%;
    color: #000000;
    font-size: 24px;
    text-align: left;
}

.progressBar {
    width: 100%;
    background-color: white;
    border-radius: 25px;
    padding: 10px;
    height: 50px;
}

.progressIdle {
    min-width: 5%;
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 15px;
    color: black;
    background-color: #00ff00;
    font-size: 20px;
}

@media (max-width: 768px),
(orientation: portrait) {
    /* .adventureCard {
            width: 100%;
            height: 30%;
        } */

    .progressIdle {
        min-width: 15%;
    }

    .adventureCard h1 {
        font-size: 24px;
    }

    .adventureCard h2 {
        font-size: 18px;
    }

    .progressBar {
        height: 30px;
        padding: 5px;
    }

    .progressIdle {
        font-size: 15px;
    }
}