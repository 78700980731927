* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: inherit;
}

.profileScreen {
    width: 100%;
    min-height: 100vh;
    background-color: #212121;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    padding: 30px;
    padding-top: 12vh;
    /* Prevents overlap with the header */
    padding-bottom: 7vh;
    /* Prevents overlap with the footer/navBar */
}

.tempCard {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    background-color: #333333;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    text-align: left;
    margin-bottom: 20px;
}

.tempCard h1 {
    width: 100%;
    text-align: center;
    color: white;
    font-size: 2em;
}

.tempCard h2 {
    color: white;
    font-size: 1.75em;
}

.profile-item {
    min-width: 49%;
    color: white;
    background-color: #1e1e1e;
    border-radius: 8px;
    text-align: center;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    margin-top: 20px;
}

.profile-text {
    color: white;
    width: 100%;
    font-size: 1.4em;
    text-align: left;
    margin-top: 5px;
}

.progressBarBase {
    width: 100%;
    background-color: white;
    border-radius: 12px;
    padding: 5px;
}

.advXP {
    max-width: 100%;
    height: 100%;
    background-color: green;
    border-radius: 12px;
    color: green;
}

.repXP {
    max-width: 100%;
    height: 100%;
    background-color: orange;
    border-radius: 12px;
    color: orange;
}

@media (max-width: 768px) {
    .profile-item {
        width: 100%;
    }
}