@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&display=swap');
/* @import url('./assets/font.otf'); */

* {
    font-family: "Montserrat", serif;
    font-size: 12px;
    font-weight: bold;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
