.navBar {
    position: fixed;
    bottom: 0%;
    left: 0%;
    background-color: #f8c8dc;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100vw;
    height: 8dvh;
}

.navButton {
    text-align: center;
    align-items: center;
}

.navButton_Text {
    color: black;
}

.navButton img {
    max-width: 8vw;
    max-height: 75%;
}